<template>
	<div class="user">
		<div class="riqi">
			<p>日期</p>
			<div class="list">
				<p v-for="(item,index) in xdata" :key="index" :class="{active:currentIndex === index}" class="listData"
					@click="dataList(item,index)">{{item}}</p>
			</div>
		</div>
		<div>
			<van>
				<van-list style="padding:15px 15px 50px 15px;height: 100%;" v-if="goodsList.length != 0">
					<div v-for="(item,index) in goodsList" :key="index" class="goods-item">
						<div>
							<div class="imgName">
								<img :src="imageUrl+item.avatar" alt="" style="width: 60px;height: 60px;" />
								<div class="listName">
									<p>用户昵称:{{item.nickname}}</p>
									<p>手机号:{{item.tel}}</p>
								</div>
							</div>
							<div class="dataTime">
								<p>预约日期:{{item.date}}</p>
								<p>预约时间段:{{item.time}}</p>
								<p>预约时间:{{item.create_time}}</p>
							</div>
						</div>
					</div>
				</van-list>
				<van-empty description="暂无预约" v-else />
			</van>
		</div>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	import {
		userAppoint
	} from '@/api/make'
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				date: '',
				goodsList: [], // 请求数据
				imageUrl: http.imageUrl,
				xdata: [],
				currentIndex: '',
				datas: ''
			};
		},
		mounted() {
			// console.log(this.$route.query.aid)
		},
		created() {
			this.chart2()
		},
		methods: {
			getDay(day) {
				var today = new Date();
				var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
				today.setTime(targetday_milliseconds); //注意，这行是关键代码
				var tYear = today.getFullYear();
				var tMonth = today.getMonth();
				var tDate = today.getDate();
				tMonth = this.doHandleMonth(tMonth + 1);
				tDate = this.doHandleMonth(tDate);
				return tYear + "-" + tMonth + "-" + tDate;
			},
			doHandleMonth(month) {
				var m = month;
				if (month.toString().length == 1) {
					m = "0" + month;
				}
				return m;
			},
			chart2(datas) {
				// var xdata=[];
				for (var i = 0; i < 14; i++) {
					this.xdata[i] = this.getDay(+i);
				}
			},
			dataList(data, index) {
				this.datas = data
				this.currentIndex = index
				this.getList()
			},
			getList() {
				let params = {
					aid: this.$route.query.aid,
					date: this.datas
				}
				userAppoint(params).then((res) => {
					this.goodsList = res.data.data
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.user {
		min-height: calc(100vh);
		.riqi {
			width: 95%;
			// margin-top: 0.3rem;

			p {
				font-size: 1rem;
				margin-left: 15px;
				margin: 0;
				padding: 10px;
			}

			.list {
				width: 100%;
				margin-top: 0.3rem;
				display: flex;
				overflow-x: scroll;
				white-space: nowrap;

				.listData {
					font-size: 0.9rem;
					width: 4.8rem;
					height: 2rem;
					line-height: 2rem;
					border: solid 1px #ccc;
					text-align: center;
					margin: 0 10px;
					padding: 0 14px;
					color: #666;
				}
			}
		}

		.goods-item {
			background-color: #FFFFFF;
			// height: 170px;
			border-radius: 5px;
			margin-bottom: 10px;
			font-size: 14px;
			padding: 15px 10px;
			.imgName{
				display: flex;
				align-items: center;
				.listName{
					margin-left: 10px;
				}
				p{
					margin: 0;
					padding: 3px 0;
				}
			}
			.dataTime{
				margin-top: 10px;
				p{
					margin: 0;
				}
			}
		}
	}

	.active {
		color: red !important;
		border: solid 1px red !important;
	}
</style>
